import request from "../utils/request";

/**
 * web视频管理
 * @param id
 * @param state
 * @returns {AxiosPromise}
 */
export const getVideoMenu = () => {
    return request({
        url: '/sys/video/getVideoMenu',
        method: 'get',
    });
};


/**
 * 修改菜单状态
 */
export const setMenuState = (videoMenuId) => {
    return request({
        url: '/sys/video/updateVideoMenuState',
        method: 'get',
        params:{videoMenuId}
    });
};
/**
 * 修改视频状态
 */
export const setVideoState = (videoId) => {
    return request({
        url: '/sys/video/updateVideoState',
        method: 'get',
        params:{videoId}
    });
};

/**
 * web根据视频菜单分类id查询分类下的全部视频
 * @param id
 * @param state
 * @returns {AxiosPromise}
 */
export const getVideoList = (query) => {
    return request({
        url: '/sys/video/getVideoList',
        method: 'post',
        data: query
    });
};

/**
 * 新增视频子菜单
 * @param card
 * @returns {AxiosPromise}
 */
export const createVideoMenu = (videoMenu) => {
    return request({
        url: '/sys/video/insertVideoMenu',
        method: 'post',
        data: videoMenu
    });
}
/**
 * 新增父视频
 * @param card
 * @returns {AxiosPromise}
 */
export const insertVideo = (video) => {
    return request({
        url: '/sys/video/insertVideo',
        method: 'post',
        data: video
    });
}

/**
 * 新增视频解析
 * @param card
 * @returns {AxiosPromise}
 */
export const insertVideoAnalysis = (video) => {
    return request({
        url: '/sys/video/insertVideoAnalysis',
        method: 'post',
        data: video
    });
}