<template>
  <div style="padding: 20px">
  <div>
      <el-select style="width: 150px" v-model="query.regionId" placeholder="城市" class="handle-select mr10">
        <el-option @click="selectCity1(item.id)"
                   v-for="(item,index) in regionList" :key="index"
                   :label="item.name"
                   :value="item.id"></el-option>
      </el-select>
    </div>

    <div style="margin-top: 20px">
      <el-table
          :data="videoMenu"
          style="width: 100%; margin-bottom: 50px"
          row-key="id"
          :row-style="{ height: 0 + 'px' }"
          :cell-style="{ padding: 0 + 'px' }"
          height="670px"
          border
          default-expand-all
      >
        <el-table-column prop="name" label="名称" sortable />
        <el-table-column prop="icon" label="图标">
          <template v-slot="products">
            <el-image
                class="code"
                hide-on-click-modal
                :src="products.row.icon"
                :preview-src-list="[products.row.videoPoster]"
            >
              <template #error>
                <div class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </template>
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="icon" label="父类">
          <template v-slot="products">
            <span v-if="products.row.parentId == null">
              顶级
            </span>
            <span v-if="products.row.parentId != null">
              {{ products.row.fatherVideoMenu.name }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="icon" label="权限">
          <template v-slot="products">
            <el-tag v-if="products.row.role == 0">全部可见</el-tag>
            <el-tag v-if="products.row.role == 1" type="success">教练可见</el-tag>
            <el-tag v-if="products.row.role == 2" type="warning">家长可见</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="icon" label="所属城市">
          <template v-slot="products">
            {{products.row.area.name}}
          </template>
        </el-table-column>
        <el-table-column prop="icon" label="全部视频">
          <template #default="scope">
            <el-button type="text" @click="getVideoList(scope.$index)"> 视频列表 </el-button>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" sortable />
        <el-table-column prop="icon" width="110" label="状态">
          <template v-slot="products">
            <el-tooltip :content="products.row.state == 0 ? '确定关闭吗':'确定要开启吗'"
                        placement="top">
              <el-switch
                  @click="updateMenuState(products.row.id)"
                  v-model="products.row.state"
                  :active-value="0"
                  :inactive-value="1"
              >
              </el-switch>
            </el-tooltip>
          </template>
        </el-table-column>

        <el-table-column label="操作" align="center">
          <template #default="scope">
            <el-button
                type="text"
                icon="el-icon-edit"
                @click="updateCardSpe(scope.$index,scope.row)"
            >编辑
            </el-button>
            <el-popover
                placement="top-start"
                :width="200"
                trigger="click"
            >
              <template #reference>
                <el-button type="text" icon="Plus"> 新增 </el-button>
              </template>
              <div style="display:flex;justify-content: space-around">
                <el-tooltip
                    v-if="scope.row.parentId != null"
                    class="box-item"
                    effect="dark"
                    content="仅支持顶级菜单添加子类！"
                    placement="top"
                >
                  <div><el-button disabled type="text"> 新增子类 </el-button></div>
                </el-tooltip>
                <el-button v-else type="text" @click="openAddVideoMenuDialog(scope.$index)"> 新增子类 </el-button>
                <el-button type="text" @click="openAddVideoDialog(scope.$index)"> 新增视频 </el-button>
              </div>
            </el-popover>

          </template>

        </el-table-column>
      </el-table>
    </div>
<!--查看全部视频-->
    <el-dialog width="1400px" :append-to-body="false" v-model="videoListDialog" :title="selectVideoMenu.name+' - 全部视频'">
      <template #default>
        <!--添加子视频弹出层-->
        <el-dialog
            :append-to-body="true"
            v-model="addSonVideoDialog"
            v-if="addSonVideoDialog"
            width="1000px"
            :title="selectVideo.titleName+' - 添加续集'">
            <div v-loading="addVideoLoading" element-loading-text="请稍等...">
              <el-form :model="addVideo" :rules="addVideoRules" ref="addVideoRules" label-width="100px">
                <el-row>
                  <el-col :span="8">
                    <el-form-item label="视频标题" prop="titleName">
                      <el-input v-model="addVideo.titleName"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="可见权限" prop="role">
                      <el-select v-model="addVideo.role " placeholder="谁可以看">
                        <el-option label="全部可见" value="0"></el-option>
                        <el-option label="教练可见" value="1"></el-option>
                        <el-option label="家长可见" value="2"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <div style="text-align: left">
                  <el-form-item label="上传封面" prop="videoPoster">
                    <el-upload
                        style="display: flex"
                        v-loading="addOSSImageLoading"
                        class="upload"
                        :http-request="uploadImages"
                        :before-upload="beforeAvatarUpload"
                        :on-remove="handleRemoveImage"
                        :limit="1"
                        list-type="picture-card">
                     <el-button icon="Plus" text />
                    </el-upload>
                  </el-form-item>
                  <el-form-item label="上传视频" prop="videoUrl">
                    <div v-loading="addOSSVideoLoading" element-loading-text="正在上传请稍等...">
                      <el-upload
                          drag
                          :limit="1"
                          :auto-upload="false"
                          :on-change="fileChange"
                          :on-exceed="exceedFile"
                          :file-list="addOSSVideoFileList"
                      >
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                      </el-upload>
                    </div>
                  </el-form-item>
                </div>
              </el-form>
            </div>
            <div style="text-align: left;margin-left: 30px;color: red;font-size: 12px;">
              备注：此页面添加的是视频子类（“{{selectVideo.titleName}}”的视频续集），如需添加视频分类请返回菜单列表，然后点击对应菜单的"新增"视频。</div>
            <template #footer>
              <span class="dialog-footer">
                <el-button @click="addVideoDialog = false">取消</el-button>
                <el-button :disabled="addVideoLoading" type="primary" @click="createVideo('createSonVideo')">确定</el-button>
              </span>
            </template>
        </el-dialog>
        <!--查看视频弹出层-->
        <el-dialog
            :append-to-body="true"
            v-model="selectVideoDialog"
            width="800px"
            :title="selectVideo.titleName">
          <div style="padding: 20px">
            <video
                controls="controls"
                :src="selectVideo.videoUrl"
                muted
                autoplay
                style="width: 100%;border-radius: 8px"
                ref="video">
            </video>
          </div>
        </el-dialog>
        <!--添加视频解析-->
        <el-dialog
            @open = "showVideo()"
            :title="addVideo.titleName+' - 添加视频解析'"
            :append-to-body="true"
            v-model="addVideoAnalysisDialog"
            width="1000px">

          <template #footer>
            <div style="padding: 20px">
              <div ref="websiteEditorElem" style="height:420px;background: #ffffff;text-align: left"></div>
            </div>
            <span class="dialog-footer">
              <el-button @click="addVideoDialog = false">取 消</el-button>
              <el-button :disabled="addVideoLoading" type="primary" @click="createVideoAnalysis()">确 定</el-button>
            </span>
          </template>
        </el-dialog>
      </template>
      <template #footer>
        <div class="dialog-footer">
          <el-table
              :data="videoList"
              style="width: 100%; margin-bottom: 20px"
              row-key="id"
              :row-style="{ height: 0 + 'px' }"
              :cell-style="{ padding: 0 + 'px' }"
              height="520px"
              border
              default-expand-all
          >
            <el-table-column prop="titleName" label="名称" />
            <el-table-column prop="icon" label="视频封面">
              <template v-slot="products">
                <el-image
                    class="videoImage"
                    hide-on-click-modal
                    :src="products.row.videoPoster"
                    :preview-src-list="[products.row.videoPoster]"
                >
                  <template #error>
                    <div class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </template>
                </el-image>
              </template>
            </el-table-column>
            <el-table-column prop="icon" label="视频">
              <template v-slot="products">
                <el-button type="text" @click="setThisVideo(products.row),selectVideoDialog = true"> 查看视频 </el-button>
              </template>

            </el-table-column>
            <el-table-column prop="icon" label="课程老师">
              <template v-slot="products">
              <span>{{products.row.coach==null ? '暂无老师': products.row.coach.coachname}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="icon" label="权限">
              <template v-slot="products">
                <el-tag v-if="products.row.role == 0">全部可见</el-tag>
                <el-tag v-if="products.row.role == 1" type="success">教练可见</el-tag>
                <el-tag v-if="products.row.role == 2" type="warning">家长可见</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="type" label="集合id"/>
            <el-table-column prop="sort" label="集合排序"/>

            <el-table-column prop="createTime" width="200" label="创建时间"/>
            <el-table-column prop="icon" label="状态">
              <template v-slot="products">
                <el-tooltip :content="products.row.state == 0 ? '确定关闭吗':'确定要开启吗'"
                            placement="top">
                  <el-switch
                      @click="updateVideoState(products.row.id)"
                      v-model="products.row.state"
                      :active-value="0"
                      :inactive-value="1"
                  >
                  </el-switch>
                </el-tooltip>
              </template>
            </el-table-column>

            <el-table-column label="操作" width="220" align="center">
              <template #default="scope">
                <el-button
                    type="text"
                    icon="el-icon-edit"
                    @click="updateCardSpe(scope.$index,scope.row)"
                >编辑
                </el-button>
                <el-button type="text" @click="setThisVideo(scope.row),addSonVideoDialog = true" icon="Plus"> 新增 </el-button>
                <el-button type="text" @click="openAnalysis(scope.row);" icon="Plus"> 解析 </el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="pagination">
            <el-pagination
                background
                layout="total, prev, pager, next"
                :current-page="videoQuery.pageIndex"
                :page-size="videoQuery.pageSize"
                :total="VideoListTotal"
                @current-change="VideoListPageChange"
            ></el-pagination>
          </div>
        </div>
      </template>
    </el-dialog>
<!--添加父视频-->
    <el-dialog v-if="addVideoDialog" width="1000px"  :append-to-body="false" v-model="addVideoDialog" :title="video.name+' - 添加视频'">
      <template #footer>
        <div class="dialog-footer">
          <div v-loading="addVideoLoading" element-loading-text="请稍等...">
            <el-form :model="addVideo" :rules="addVideoRules" ref="addVideoRules" label-width="100px">
              <el-row>
                <el-col :span="8">
                  <el-form-item label="视频标题" prop="titleName">
                    <el-input v-model="addVideo.titleName"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="可见权限" prop="role">
                    <el-select v-model="addVideo.role " placeholder="谁可以看">
                      <el-option label="全部可见" value="0"></el-option>
                      <el-option label="教练可见" value="1"></el-option>
                      <el-option label="家长可见" value="2"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col style="text-align: left">
                  <el-form-item label="上传封面" prop="videoPoster">
                    <el-upload
                        style="display: flex"
                        v-loading="addOSSImageLoading"
                        class="upload"
                        :http-request="uploadImages"
                        :before-upload="beforeAvatarUpload"
                        :on-remove="handleRemoveImage"
                        :limit="1"
                        list-type="picture-card">
                     <el-button icon="Plus" text />
                    </el-upload>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12" style="text-align: left">
                  <el-form-item label="上传视频" prop="videoUrl">
                    <div v-loading="addOSSVideoLoading" element-loading-text="正在上传请稍等...">
                    <el-upload
                        drag
                        :limit="1"
                        :auto-upload="false"
                        :on-change="fileChange"
                        :on-exceed="exceedFile"
                        :file-list="addOSSVideoFileList"
                    >
                      <i class="el-icon-upload"></i>
                      <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                    </el-upload>
                    </div>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
            <div style="text-align: left;margin-left: 30px;color: red;font-size: 12px;">
              备注：此页面添加的是视频父类（菜单里面的视频分类），如需添加视频续集请点击视频列表，然后点击"新增"视频。</div>
            <span class="dialog-footer">
              <el-button @click="addVideoDialog = false">取 消</el-button>
              <el-button type="primary" @click="createVideo()">创 建</el-button>
            </span>
          </div>
        </div>
      </template>
    </el-dialog>
<!--添加子菜单-->
    <el-dialog v-if="addVideoMenuDialog" width="800px" :append-to-body="false" v-model="addVideoMenuDialog" :title="video.name+' - 添加子菜单'">
      <template #footer>
        <div class="dialog-footer">
          <div style="padding-right: 20px">
            <el-form :model="addVideoMenu" :rules="addVideoMenuRules" ref="addVideoMenuRules" label-width="100px">
              <el-row>
                <el-col :span="8">
                  <el-form-item label="菜单名称" prop="name">
                    <el-input v-model="addVideoMenu.name"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="可见权限" prop="role">
                    <el-select v-model="addVideoMenu.role " placeholder="谁可以看">
                      <el-option label="全部可见" value="0"></el-option>
                      <el-option label="教练可见" value="1"></el-option>
                      <el-option label="家长可见" value="2"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="所属城市" prop="regionId">
                    <el-select v-model="addVideoMenu.regionId" placeholder="请选择">
                      <el-option v-for="(item,index) in regionList" :key="index"
                                 :label="item.name"
                                 :value="item.id"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <div v-loading="addOSSImageLoading">
                <el-form-item label="菜单图标" prop="icon">
                  <el-upload
                      style="display: flex"
                      class="upload"
                      :http-request="uploadImages"
                      :before-upload="beforeAvatarUpload"
                      :on-remove="handleRemoveImage"
                      :limit="1"
                      list-type="picture-card">
                   <el-button icon="Plus" text />
                  </el-upload>
                </el-form-item>
              </div>
            </el-form>
            <span class="dialog-footer">
              <el-button @click="addVideoDialog = false">取消</el-button>
              <el-button type="primary" @click="createSonMenu();">确定</el-button>
            </span>
          </div>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>

import {selectAreaByExpress} from "@/api/area";
import {getVideoMenu,getVideoList,createVideoMenu,insertVideo,setMenuState,setVideoState,insertVideoAnalysis} from "@/api/video";
import {uploadImage,uploadVideo}from '@/api/helpManual'
import E from 'wangeditor'
export default {
  name: "Video",
  data() {
    return {
      addOSSImage:null,
      addOSSImageLoading:false,
      addOSSVideoFileList:[],
      addOSSVideoDuration:null,
      addOSSVideoLoading:false,

      //视频菜单对象和表单验证
      addVideoMenuDialog:false,
      addVideoMenu:{
        name:"",
        role:null,
        parentId:null,
        icon:"",
      },
      addVideoMenuRules: {//卡片
        name: [
          {required: true, message: '请输入子菜单名称！', trigger: 'blur'},
        ],
        role: [
          {required: true, message: '请选择可见权限！', trigger: 'change'},
        ],
        icon: [
          {required: true, message: '请选择视频封面！', trigger: 'change'},
        ],
        regionId: [
          {required: true, message: '请选择所属城市', trigger: 'change'}
        ],
      },

      //视频对象和表单验证
      addVideoDialog:false,
      addVideoLoading:false,
      addVideo:{
        titleName:"",
        videoPoster:"",
        videoUrl:"",
        role:null,
        duration:"",
        fatherId:null,
        type:null
      },
      addVideoRules: {//卡片
        titleName: [
          {required: true, message: '请输入视频标题！', trigger: 'blur'},
        ],
        role: [
          {required: true, message: '请选择可见权限！', trigger: 'change'},
        ],
        videoPoster: [
          {required: true, message: '请上传视频封面（点击创建即可清除该提示）！', trigger: 'change'},
        ],
        videoUrl: [
          {required: true, message: '请上传视频（点击创建即可清除该提示）！', trigger: 'change'}
        ],
      },

      addVideoAnalysisDialog:false,
      addAnalysis:{
        videoAnalysis:"",
        id:null
      },

      regionList: [],
      videoMenu:[],
      videoList:[],
      video:{},
      videoListDialog:false,
      videoDialog:false,
      selectVideo:{},
      selectVideoMenu:{},
      selectVideoDialog:false,
      addSonVideoDialog:false,


      query: {
        regionId:null,
      },
      videoQuery: {
        menuId: null,
        pageIndex:0,
        pageSize:9
      },
      VideoListTotal:0
    }
  },
  created() {
    selectAreaByExpress().then(res => {
      if (res.code == 200) {
        this.regionList = res.data
      } else {
        this.$message.error("获取城市数据失败");
      }
    })
    this.getMenu();
  },

  methods: {
    /**
     * 修改视频菜单状态
     */
    updateMenuState(id){
      setMenuState(id).then(val => {
        if (val.code == 200){
          this.getMenu();
          this.$message.success(`状态修改成功！`);
        }else {
          this.$message.error("状态修改失败！");
        }
      })
    },
    /**
     * 修改视频状态
     */
    updateVideoState(id){
      setVideoState(id).then(val => {
        if (val.code == 200){
          this.getVideoListByMenuId();
          this.$message.success(`状态修改成功！`);
        }else {
          this.$message.error("状态修改失败！");
        }
        console.log(val)
      })
    },
    /**
     * 创建视频子菜单
     */
    async createSonMenu() {
      let newVar = await this.uploadOSSImage();
      if (newVar != false){
        this.addVideoMenu.icon = newVar;
      }
      this.$refs['addVideoMenuRules'].validate((valid) => {
        if (valid==true){
          createVideoMenu(this.addVideoMenu).then(val => {
            if (val.code == 200){
              this.addOSSImage = null;
              this.addVideoMenu = {
                name:"",
                role:null,
                parentId:null,
                icon:"",
              };
              this.addVideoMenuDialog = false;
              this.$message.success(`添加成功！`);
            }else {
              this.$message.error("添加失败！");
            }
          })
        }
      })
      console.log(this.addVideoMenu)
      // addVideoDialog = false
    },
    /**
     * 创建视频
     */
    async createVideo(type){
      this.addVideoLoading = true;
      let image = await this.uploadOSSImage();
      if (image != false){
        this.addVideo.videoPoster = image;
      }
      let video = await this.uploadOSSVideo();
      if (video != false){
        this.addVideo.duration = this.addOSSVideoDuration;
        this.addVideo.videoUrl = video;
      }
      this.$refs['addVideoRules'].validate((valid) => {
        if (valid==true){
          insertVideo(this.addVideo).then(val => {
            if (val.code == 200){
              this.addOSSVideoFileList = [];
              this.addOSSImage = null;
              this.addVideo = {
                titleName:"",
                videoPoster:"",
                videoUrl:"",
                role:null,
                duration:"",
                fatherId:null,
                type:null
              };
              this.addVideoLoading = false;
              this.addVideoDialog = false;
              this.addSonVideoDialog = false;
              if (type == "createSonVideo"){
                this.getVideoListByMenuId();
              }
              this.$message.success(`添加成功！`);
            }else {
              this.$message.error("添加失败！");
            }
          })
        }
        this.addVideoLoading = false;
      })
    },
    /**
     * 创建视频解析
     */
    createVideoAnalysis(){
      this.addAnalysis.videoAnalysis = this.phoneEditor.txt.html();
      insertVideoAnalysis(this.addAnalysis).then(val => {
        if (val.code == 200){
          this.addAnalysis = {videoAnalysis:"",id:null};
          this.phoneEditor.txt.html("");
          this.addVideoAnalysisDialog = false;
          this.$message.success(`添加成功！`);
        }else {
          this.$message.error("添加失败！");
        }
      })
    },
    // 选中城市
    selectCity1(val) {
      this.query.regionId = val;
      console.log(this.query.regionId)
    },
    getMenu() {
      getVideoMenu().then(res => {
        if (res.code == 200) {
          this.videoMenu = res.data
        } else {
          this.$message.error("数据获取失败！");
        }
      })
    },
    getVideoList(index) {
      this.selectVideoMenu = this.videoMenu[index];
      this.videoQuery.menuId = this.selectVideoMenu.id;
      this.videoListDialog = true;
      this.getVideoListByMenuId();
    },
    VideoListPageChange(val){
      this.videoQuery.pageIndex = val;
      this.getVideoListByMenuId();
    },
    /**
     *根据视频菜单id查询视频
     */
    getVideoListByMenuId(){
      getVideoList(this.videoQuery).then(val => {
        if (val.code == 200) {
          this.videoList = val.data.records;
          this.VideoListTotal = val.data.total;
        } else {
          this.$message.error("数据获取失败！");
        }
      })
    },
    setThisVideo(val) {
      this.selectVideo = val;
      this.addVideo.type = val.type;
      this.addVideo.fatherId = val.fatherId;
    },
    openAddVideoDialog(index) {
      this.addVideo.type = null;
      this.video = this.videoMenu[index];
      this.addVideoDialog = true;
      this.addVideo.fatherId = this.video.id;
    },
    openAnalysis(item){
      this.addVideoAnalysisDialog = true;
      this.addVideo = item;
      this.addAnalysis.id = item.id;
      this.addAnalysis.videoAnalysis = this.toHtml(item.videoAnalysis);
      this.phoneEditor.txt.html('<p>'+this.toHtml(item.videoAnalysis) + '</p>');
    },
    openAddVideoMenuDialog(index){
      this.video = this.videoMenu[index];
      this.addVideoMenuDialog = true;
      this.addVideoMenu.parentId = this.video.id;
    },
    uploadImages({file}) {
      this.addOSSImage = file;
    },
    /**
     * 把本地的视频封面上传到阿里云OSS
     */
    uploadOSSImage(){
      if (this.addOSSImage == null){
        return false;
      }
      this.addOSSImageLoading = true;
      const data = new FormData();
      data.append(
          'multipartFile', this.addOSSImage
      )
      data.append(
          'flag', 'video/videoCover'
      )
      data.append(
          'fileName', this.addOSSImage.name
      )
      return new Promise((resolve) => {
        uploadImage(data).then(res=>{
          this.addOSSImageLoading = false;
          this.$message.success(`视频封面上传成功！`);
          resolve(res.data)
        })
      })

    },
    /**
     * 把本地的视频上传到阿里云OSS
     */
    uploadOSSVideo(){
      if (this.addOSSVideoFileList.length<1){
        return false;
      }
      this.addOSSVideoLoading = true;
      const data = new FormData();
      data.append(
          'multipartFile', this.addOSSVideoFileList[0]
      )
      data.append(
          'flag', 'video/videoUrl'
      )
      data.append(
          'fileName', this.addOSSVideoFileList[0].name
      )
      return new Promise((resolve) => {
        uploadVideo(data).then(res=>{
          this.addOSSVideoLoading = false;
          this.$message.success(`视频上传成功！`);
          resolve(res.data)
        })
      })
    },

    //判断上传的文件
    exceedFile(files, fileList) {
      this.$message.warning(
          `只能选择 ${1} 个视频，当前共选择了 ${
              files.length + fileList.length
          } 个！`
      );
    },
    // 文件状态改变时的钩子
    fileChange(file) {
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      if (extension !== "mp4") {
        this.addOSSVideoFileList = [];
        this.$message.warning("只能上传后缀是.mp4！");
        return;
      }
      const getVideoInfo = new Promise((resolve) => {
        const videoElement = document.createElement("video");
        videoElement.src = URL.createObjectURL(file.raw);
        videoElement.addEventListener("loadedmetadata", function () {
          resolve({
            duration: videoElement.duration,
          });
        });
      });
      getVideoInfo.then((videoInfo) => {
        this.addOSSVideoDuration = this.formatSeconds(videoInfo.duration);
      })
      this.addOSSVideoFileList = [];
      this.addOSSVideoFileList.push(file.raw);
    },
    /**
     * 文件限制
     */
    beforeAvatarUpload(file) {
      const mp4 = file.name.split('.')[1] === 'mp4';
      const isJPG = file.name.split('.')[1] === 'jpg';
      const isPNG = file.name.split('.')[1] === 'png';
      const isGIF = file.name.split('.')[1] === 'gif';
      const one = file.limit === 1;
      if (!mp4 && !isJPG && !isPNG && !isGIF) {
        this.$message.error('上传文件格式有误!');
      }
      if (one > 2) {
        this.$message.error('文件上传仅限一个!');
      }
      return (mp4 || isJPG || isPNG || isGIF);
    },

    /**
     * 删除文件上传
     */
    handleRemoveImage() {
      this.addOSSImage = null;
    },
    // 秒数转化为时分秒
    formatSeconds(value) {
      console.log(value)
      //  秒
      let second = parseInt(value)
      console.log(second)
      //  分
      let minute = 0
      //  小时
      let hour = 0
      //  天
      //  let day = 0
      //  如果秒数大于60，将秒数转换成整数
      if (second > 60) {
        //  获取分钟，除以60取整数，得到整数分钟
        minute = parseInt(second / 60)
        //  获取秒数，秒数取佘，得到整数秒数
        second = parseInt(second % 60)
        //  如果分钟大于60，将分钟转换成小时
        if (minute > 60) {
          //  获取小时，获取分钟除以60，得到整数小时
          hour = parseInt(minute / 60)
          //  获取小时后取佘的分，获取分钟除以60取佘的分
          minute = parseInt(minute % 60)
          //  如果小时大于24，将小时转换成天
          //  if (hour > 23) {
          //    //  获取天数，获取小时除以24，得到整天数
          //    day = parseInt(hour / 24)
          //    //  获取天数后取余的小时，获取小时除以24取余的小时
          //    hour = parseInt(hour % 24)
          //  }
        }
      }

      let result = '' + parseInt(second) + '秒'
      if (minute > 0) {
        result = '' + parseInt(minute) + '分' + result
      }
      if (hour > 0) {
        result = '' + parseInt(hour) + '小时' + result
      }
      //  if (day > 0) {
      //    result = '' + parseInt(day) + '天' + result
      //  }
      console.log('result：', result)
      return result
    },
    showVideo(){
      if (this.showVideos == null){
        this.phoneEditor = new E(this.$refs.websiteEditorElem);
        this.phoneEditor.create();
        this.phoneEditor.txt.html();
        this.phoneEditor.config.showLinkImg = false;
        this.showVideos = false;
      }
    },
    toHtml(str) {
      var arrEntities = {
        'lt': '<',
        'gt': '>',
        'nbsp': ' ',
        'amp': '&',
        'quot': '"'
      };
      return str.replace(/&(lt|gt|nbsp|amp|quot);/ig, function (all, t) {
        return arrEntities[t]
      })
    },
  },
  mounted() {

  }
}
</script>

<style scoped>
.code {
  margin-top: 4px;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background-color: #efefef;
}
.videoImage{
  margin-top: 4px;
  width: 80px;
  height: 40px;
  border-radius: 8px;
  background-color: #efefef;
}
.disUoloadSty /deep/ .el-upload--picture-card{
   display:none;   /* 上传按钮隐藏 */
 }
</style>